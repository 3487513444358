import { Component } from "@angular/core";

@Component({
  selector: "app-home-main-slider",
  templateUrl: "./home-main-slider.component.html",
  styleUrls: ["./home-main-slider.component.scss"],
})
export class HomeMainSliderComponent {
  title = "ngSlick";

  slides = [
    {
      link: "/danvi2",
      component: "Danvi2Component",
      img: "../../../../assets/img/danvi-main-bg.webp",
      number: "01",
      title: "Danvi",
      description: "Creating a visual identity for a new coffee brand.",
      position: "75%",
    },
    {
      link: "/vittaa-app",
      component: "VittaaAppComponent",
      img: "../../../../assets/img/vittaa-main-bg.webp",
      number: "02",
      title: "Vittaa",
      description:
        "Designing an online meet up platform for people who want to run, bike or hike together.",
      position: "75%",
    },
    {
      link: "/ondergrondsverbond",
      component: "OndergrondsverbondComponent",
      img: "../../../../assets/img/ov-main-bg.webp",
      number: "03",
      title: "Ondergrondsverbond",
      description: "Uniting diverse dance events.",
      position: "75%",
    },
    {
      link: "/kat-website",
      component: "KatWebsiteComponent",
      img: "../../../../assets/img/kat-main-bg.webp",
      number: "04",
      title: "Knivesandtools",
      description:
        "Visual design for an international web shop offering a wide range of kitchen and outdoor tools. ",
    },

    {
      link: "/grailer",
      component: "GrailerComponent",
      img: "../../../../assets/img/grailer-main-bg.webp",
      number: "05",
      title: "Grailer",
      description: "Branding a new private label for grail pocket knives.",
      position: "right",
    },
    {
      link: "/logo-design",
      component: "LogoDesignComponent",
      img: "../../../../assets/img/logo-main-bg.webp",
      number: "06",
      title: "Logo design",
      description:
        "A small selection of logos and workmarks I made through the years.",
    },
  ];

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
  };

  slickInit(e) {
    console.log("slick initialized");
  }

  breakpoint(e) {
    console.log("breakpoint");
  }

  constructor() {}
}
